<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <sidebar-group title="YouTube Video|Vidéo YouTube">
            <b-form-group>
<!--                <template #label>-->
<!--                    <btn-language-prop class="float-right" v-model="block" field="videoUrl"/>-->
<!--                    <span v-tr>Video|Vidéo</span>-->
<!--                </template>-->
                <input-url title="Youtube Video" :name="block.name" v-model="block.videoUrl" :thumbnail="block.thumbnail" glyph="fab fa-youtube-play"
                           placeholder="Replace Video|Remplacer la vidéo" variant="light-primary" block @edit="videoModalVisible = true"/>
            </b-form-group>
            <b-form-group>
                <input-border-radius v-model="block.iframe.borderRadius"/>
            </b-form-group>
            <b-form-group label-cols="8">
                <template #label>
                    <span v-tr>Height|Hauteur</span>
                    <btn-responsive-prop v-model="block.iframe" field="height"/>
                </template>
                <e-input type="number" v-model.number="block.iframe.mediaProxy.height" :min="20" :max="2000" placeholder="Auto" state="disabled"/>
                <span class="unit">px</span>
            </b-form-group>
            <b-form-group>
                <b-checkbox v-model="block.showClosedCaptions" @change="updateVideoUrl"><span v-tr>Closed Captions|Sous-Titres</span></b-checkbox>
            </b-form-group>
            <b-form-group>
                <b-checkbox v-model="block.autoplay" @change="updateVideoUrl"><span v-tr>Autoplay|Jouer automatiquement</span></b-checkbox>
            </b-form-group>
            <b-form-group>
                <b-checkbox v-model="block.muted" @change="updateVideoUrl"><span v-tr>Muted|Désactiver le son</span></b-checkbox>
            </b-form-group>
        </sidebar-group>

        <b-modal v-model="videoModalVisible" :title="'Select YouTube Video|Choisir une vidéo YouTube'|tr" size="lg">
            <b-form-group :label="'Video URL|URL de la vidéo'|tr">
                <e-input type="url" v-model="videoUrl" placeholder="Ex: https://www.youtube.com/X12345678"/>
                <div class="form-help mt-2" v-tr>
                    YouTube Shorts are not supported yet|Les Extraits YouTube ne sont pas encore supportés
                </div>
            </b-form-group>

            <template #modal-footer>
                <b-btn variant="secondary" @click="videoModalVisible = false" v-tr>Cancel|Annuler</b-btn>
                <b-btn variant="primary" :disabled="!videoUrl" @click="loadYoutubeVideo">
                    <span v-tr>Select|Appliquer</span>
                </b-btn>
            </template>
        </b-modal>
    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";
import BtnLanguageProp from "@/components/editor/properties/components/btn-language-prop.vue";
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";
import InputLink from "@/components/editor/properties/inputs/input-link.vue";
import InputUrl from "@/components/editor/properties/inputs/input-url.vue";
import EInput from "../../../../../vue-components/components/e-input.vue";
import FileHelper from "../../../../../vue-components/helpers/FileHelper.js";
import NetworkV2 from "../../../../../vue-components/helpers/NetworkV2.js";

// properties-block-youtube-video #hot-reload-debug
export default {
    name: `properties-block-youtube-video`,
    components: {BtnLanguageProp, InputUrl, SidebarGroup, InputBorderRadius, InputLink, EInput, PropertiesBlock},
    extends: PropertiesBlock,
    data() {
        return {
            videoUrl: ``,
            videoModalVisible: false
        }
    },
    mounted() {
        if (!this.block.iframe.src) {
            this.videoModalVisible = true;
        } else {
            this.videoUrl = this.block.videoUrl;
        }
    },
    methods: {
        loadYoutubeVideo() {
            this.block.videoUrl = this.videoUrl;
            this.block.valid = true;
            if (!this.block.videoUrl) {
                return this.block.setValidation(false, `The URL is Empty|L'URL est vide`);
            } else if (!this.block.videoUrl.startsWith(`https://`)) {
                return this.block.setValidation(false, `The URL must start with https://|L'URL doit commencer par https://`);
            }
            this.showLoading();

            NetworkV2.get(`/youtube/info?url=${this.block.videoUrl}`, {}, {system_api: true})
                .then(resp => {
                    this.block.videoMetadata = resp.data;
                    if (!this.block.videoMetadata.info) {
                        return this.block.setValidation(false, `Invalid YouTube URL|Cette URL n'est pas valide`)
                    }

                    if (this.block.videoMetadata.info.snippet.title.length > 0) {
                        this.block.name = this.block.videoMetadata.info.snippet.title.substring(0, 50);
                    }
                    this.block.description = this.block.videoMetadata.info.snippet.description;
                    this.block.duration = this.block.videoMetadata.durationSeconds;
                    if (this.block.videoMetadata.iframeUrl) {
                        this.block.iframe.src = this.block.videoMetadata.iframeUrl;
                    }
                    this.updateVideoUrl();
                    this.videoModalVisible = false;

                    FileHelper.loadImageWithProxyAsBase64(this.block.videoMetadata.thumbnailSrc)
                        .then(image => {
                            this.block.thumbnail = image.src;
                            this.hideLoading();
                        })
                        .catch(e => {
                            console.error(e);
                            this.showErrorMessage();
                            this.block.setValidation(false, `Couldn't load video preview|Impossible de charger l'aperçu de la vidéo`);
                        });
                })
                .catch(e => {
                    console.error(e);
                    this.showErrorMessage();
                    this.block.setValidation(false, `Couldn't load video preview|Impossible de charger l'aperçu de la vidéo`);
                });
        },
        updateVideoUrl() {
            this.block.updateVideoUrl();
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
